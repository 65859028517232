import { useParams } from "react-router-dom";
import Table from "../components/Table";
import { useData } from "../hooks/useData";
import TitleWithDescr from "../components/common/TitleWithDescr";

const Network = () => {
  const { networkId } = useParams();

  const { networksData: data } = useData();

  return (
    <>
      {networkId && (
        <>
          <TitleWithDescr
            title={data[networkId].name}
            descr={data[networkId].networkDescr}
          />
          <div className="mt-8 flex flex-col">
            <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle px-6 lg:px-8">
                {data[networkId].networkData.map((d) => (
                  <Table key={d.tableName} data={d} />
                ))}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Network;
