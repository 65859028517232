import { FC } from "react";
import LogoLink from "./LogoLink";
import { TbArrowsRightLeft } from "react-icons/tb";

type RelayerBlockProps = {
  mainName: string;
  chName: string;
  mainLink: string;
  chLink: string;
  mainLogo: string;
  chLogo: string;
};

const RelayerBlock: FC<RelayerBlockProps> = ({
  mainName,
  chName,
  mainLink,
  chLink,
  mainLogo,
  chLogo,
}) => {
  return (
    <div
      id={mainName}
      className="p-5 rounded-lg shadow-lg border flex justify-between items-center gap-4"
    >
      <LogoLink name={mainName} logo={mainLogo} link={mainLink} />
      <TbArrowsRightLeft className="text-5xl text-indigo-800" />
      <LogoLink name={chName} logo={chLogo} link={chLink} />
    </div>
  );
};

export default RelayerBlock;
