import { Menu, Transition } from "@headlessui/react";
import React from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { useData } from "../hooks/useData";

const Header: React.FC<any> = () => {
  const { networksData: networks } = useData();
  const location = useLocation();
  const params = useParams();

  return (
    <>
      {networks && (
        <div className="bg-gray-800">
          <div className=" mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <div className="flex h-16 items-center justify-between">
              <div className="flex items-center">
                <div className="flex-shrink-0">
                  <Link to="/">
                    <img
                      className="block h-8 w-auto lg:hidden"
                      src="/logo.png"
                      alt="Your Company"
                    />
                    <img
                      className="hidden h-16 w-auto lg:block"
                      src="/logo.png"
                      alt="Your Company"
                    />
                  </Link>
                </div>
                <div className="ml-6">
                  <div className="relative flex space-x-4">
                    <Link
                      to="/"
                      className={`rounded-md px-3 py-2 text-xs sm:text-sm font-medium ${
                        location.pathname === "/"
                          ? "bg-gray-900 text-white"
                          : "text-gray-300 hover:bg-gray-700 hover:text-white"
                      }`}
                    >
                      Home
                    </Link>
                    <Menu>
                      <div className="relative z-10">
                        <Menu.Button>
                          <div
                            className={`rounded-md px-3 py-2 text-xs sm:text-sm font-medium ${
                              location.pathname.includes("networks")
                                ? "bg-gray-900 text-white"
                                : "text-gray-300 hover:bg-gray-700 hover:text-white"
                            }`}
                          >
                            Networks
                          </div>
                        </Menu.Button>
                        <Transition
                          enter="transition duration-100 ease-out"
                          enterFrom="transform scale-95 opacity-0"
                          enterTo="transform scale-100 opacity-100"
                          leave="transition duration-75 ease-out"
                          leaveFrom="transform scale-100 opacity-100"
                          leaveTo="transform scale-95 opacity-0"
                        >
                          <Menu.Items className="absolute top-2 left-0 bg-white rounded min-w-[100px] border border-gray-200 shadow whitespace-nowrap z-[10000] max-h-[300px] overflow-y-auto">
                            {networks &&
                              Object.keys(networks).map((n) => (
                                <Menu.Item key={networks[n].name}>
                                  {({ active }) => (
                                    <Link
                                      className={`${
                                        params.networkId &&
                                        params.networkId === networks[n].name
                                          ? "cursor-default bg-gray-200"
                                          : ""
                                      } block py-2 px-3 hover:bg-gray-300 text-sm ${
                                        active && "bg-gray-300 "
                                      }`}
                                      to={`/networks/${n}`}
                                    >
                                      {networks[n].name}
                                    </Link>
                                  )}
                                </Menu.Item>
                              ))}
                          </Menu.Items>
                        </Transition>
                      </div>
                    </Menu>
                    <Link
                      to="/relayers"
                      className={`rounded-md px-3 py-2 text-xs sm:text-sm font-medium ${
                        location.pathname === "/relayers"
                          ? "bg-gray-900 text-white"
                          : "text-gray-300 hover:bg-gray-700 hover:text-white"
                      }`}
                    >
                      Relayers
                    </Link>
                    <Link
                      to="/about"
                      className={`rounded-md px-3 py-2 text-xs sm:text-sm font-medium ${
                        location.pathname === "/about"
                          ? "bg-gray-900 text-white"
                          : "text-gray-300 hover:bg-gray-700 hover:text-white"
                      }`}
                    >
                      About us
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Header;
