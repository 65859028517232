import { FC } from "react";
import { Oval } from "react-loader-spinner";

type LoaderProps = {
  size: number;
  color?: string;
  secondaryColor?: string;
};

const Loader: FC<LoaderProps> = ({
  size,
  color = "#1f2937",
  secondaryColor = "#4b5563",
}) => {
  return (
    <div className="text-center mt-32">
      <Oval
        height={size}
        width={size}
        wrapperClass="w-32 mx-auto"
        visible={true}
        ariaLabel="oval-loading"
        color={color}
        secondaryColor={secondaryColor}
        strokeWidth={4}
        strokeWidthSecondary={4}
      />
    </div>
  );
};

export default Loader;
