import { FC } from "react";

type ChannelsBlockProps = {
  imgSrc: string;
  channelCount: number;
  channelName: string;
};

const ChannelsBlock: FC<ChannelsBlockProps> = ({
  imgSrc,
  channelCount,
  channelName,
}) => {
  return (
    <a
      href={`#${channelName}`}
      className="flex items-center justify-between border rounded-lg shadow-lg py-5 px-8 hover:bg-blue-600"
    >
      <div className="w-16 h-16 flex items-center justify-center">
        <img src={imgSrc} alt={channelName} />
      </div>
      <div>{channelCount} channels</div>
    </a>
  );
};

export default ChannelsBlock;
