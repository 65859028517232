import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import Loader from "../components/common/Loader";

export type TNetworkDataEl = { tableName: string; tableData: any[] };

export type TDataEl = {
  name: string;
  networkDescr: string;
  networkLogo: string;
  networkData: TNetworkDataEl[];
};

export type TData = {
  [key: string]: TDataEl;
};

export interface TRelayerChannel {
  name: string;
  logo: string;
  link: string;
}

export interface TRelayerEl extends TRelayerChannel {
  channels: TRelayerChannel[];
}

type TDataContext = {
  networksData: TData;
  relayerData: TRelayerEl[];
};

const DataContext = createContext({} as TDataContext);

export const useData = () => {
  return useContext(DataContext);
};

const initialData = {
  networksData: {},
  relayerData: [],
};

const DataProvider = ({ children }: { children: ReactNode }) => {
  const [data, setData] = useState<TDataContext>(initialData);

  const getNetworksData = async () => {
    const res = await fetch("/data/data.json");
    const data: TData = await res.json();
    return data;
  };

  const getRelayerData = async () => {
    const res = await fetch("/data/relayer.json");
    const data: TRelayerEl[] = await res.json();
    return data;
  };

  const getData = async () => {
    const [networksData, relayerData] = await Promise.all([
      getNetworksData(),
      getRelayerData(),
    ]);

    setData({ networksData, relayerData });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <DataContext.Provider value={data}>
      {Object.keys(data.networksData).length > 0 &&
      data.relayerData.length > 0 ? (
        children
      ) : (
        <Loader size={50} />
      )}
    </DataContext.Provider>
  );
};

export default DataProvider;
