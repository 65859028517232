import { useData } from "../hooks/useData";
import NetworkBlock from "../components/common/NetworkBlock";

const Home = () => {
  const { networksData: networks } = useData();

  return (
    <>
      <h1 className="text-2xl font-bold mb-10">Networks</h1>
      <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
        {Object.keys(networks).map((n, ind) => (
          <NetworkBlock
            key={`${n}-${ind}`}
            networkData={networks[n]}
            name={n}
          />
        ))}
      </ul>
    </>
  );
};

export default Home;
