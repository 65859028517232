import { TNetworkDataEl } from "../hooks/useData";

const Table = ({ data }: { data: TNetworkDataEl }) => {
  return (
    <>
      <div className="mb-2 text-lg font-medium">{data.tableName}</div>
      <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 rounded-lg mb-10">
        <table className="min-w-full divide-y divide-gray-300">
          <thead className="bg-gray-50">
            <tr>
              {Object.keys(data.tableData[0]).map((k) => (
                <th
                  key={`${data.tableName}-{${k}`}
                  scope="col"
                  className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 uppercase"
                >
                  {k}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="bg-white">
            {data.tableData.map((row: any, ind: number) => (
              <tr
                key={`${data.tableName}-${ind}`}
                className={ind % 2 === 0 ? undefined : "bg-gray-50"}
              >
                {Object.keys(row).map((cell: any, cellInd: number) => (
                  <td
                    key={`row-${ind}-${cellInd}`}
                    className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6"
                  >
                    {row[cell]}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default Table;
