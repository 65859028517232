import { FC } from "react";

type TitleWithDescrProps = { title: string; descr: string };

const TitleWithDescr: FC<TitleWithDescrProps> = ({ title, descr }) => {
  return (
    <div className="sm:flex sm:items-center">
      <div className="sm:flex-auto">
        <h1 className=" text-gray-900 text-2xl font-bold">{title}</h1>
        <p className="mt-2 text-sm text-gray-700">{descr}</p>
      </div>
    </div>
  );
};

export default TitleWithDescr;
