import ReactDOM from "react-dom/client";
import "./styles/tailwind.css";
import App from "./App";
import React from "react";
import DataProvider from "./hooks/useData";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Network from "./routes/Network";
import About from "./routes/About";
import Home from "./routes/Home";
import Relayer from "./routes/Relayer";

export const routes = [
  {
    path: "/",
    element: <App />,
    children: [
      { path: "/", element: <Home /> },
      { path: "/networks/:networkId", element: <Network /> },
      {
        path: "/relayers",
        element: <Relayer />,
      },
      {
        path: "/about",
        element: <About />,
      },
    ],
  },
];

const router = createBrowserRouter(routes);

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <DataProvider>
      <RouterProvider router={router} />
    </DataProvider>
  </React.StrictMode>
);
