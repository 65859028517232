import * as React from "react";

const Footer = () => {
  return (
    <div className="bg-gray-800 w-full z-[999]">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 text-white text-sm text-center py-5">
        &copy; 2021 - {new Date().getFullYear()}. All rights reserved
      </div>
    </div>
  );
};

export default Footer;
