import ChannelsBlock from "../components/common/ChannelsBlock";
import RelayerBlock from "../components/common/RelayerBlock";
import TitleWithDescr from "../components/common/TitleWithDescr";
import { useData } from "../hooks/useData";

export default function Relayer() {
  const { relayerData } = useData();

  return (
    <>
      <TitleWithDescr
        title="IBC Relayers"
        descr="Cosmowiz provides reliable infrastructure for many networks to connect chains and perform IBC transfers beetween them."
      />
      <div className="mt-8 grid grid-cols-4 gap-4">
        {relayerData &&
          relayerData.map((el, ind) => (
            <ChannelsBlock
              key={`channel-${el.name}-${ind}`}
              imgSrc={el.logo}
              channelCount={el.channels.length}
              channelName={el.name}
            />
          ))}
      </div>
      <div className="mt-16">
        {relayerData &&
          relayerData.map((el, ind) => (
            <div key={`relayer-${el.name}-${ind}`} className="mt-12">
              <header className="text-center font-bold uppercase text-lg mb-8">
                {el.name} Relayer Hub
              </header>
              <div className="grid grid-cols-4 gap-4">
                {el.channels.map((ch) => (
                  <RelayerBlock
                    key={`${el.name}-${ch.name}`}
                    mainName={el.name}
                    chName={ch.name}
                    mainLink={el.link}
                    chLink={ch.link}
                    mainLogo={el.logo}
                    chLogo={ch.logo}
                  />
                ))}
              </div>
            </div>
          ))}
      </div>
    </>
  );
}
