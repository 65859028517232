import { FC } from "react";
import { Link } from "react-router-dom";
import { TDataEl } from "../../hooks/useData";

type NetworkBlockProps = {
  networkData: TDataEl;
  name: string;
};

const renderTextColor = (ind: number) => {
  switch (ind) {
    case 0:
      return "bg-green-100";
    case 1:
      return "bg-indigo-100";
    case 2:
      return "bg-red-100";
  }
};

const NetworkBlock: FC<NetworkBlockProps> = ({ networkData, name }) => {
  return (
    <li
      key={networkData.name}
      className="col-span-1 flex flex-col divide-y divide-gray-200 border rounded-lg bg-white text-center shadow-lg"
    >
      <div className="flex flex-1 flex-col p-8">
        <Link
          to={`/networks/${name}`}
          className="mt-6 text-sm font-medium text-gray-900 hover:underline"
        >
          <div className="mx-auto h-32 w-32 flex-shrink-0 rounded-full mb-10">
            <img
              className="w-full h-full object-contain"
              src={networkData.networkLogo}
              alt=""
            />
          </div>

          {networkData.name}
        </Link>
        <dl className="mt-1 flex flex-grow flex-col justify-between">
          <dt className="sr-only">Networks</dt>
          <dd className="mt-3 flex flex-wrap justify-center">
            {networkData.networkData.map((nd, ind) => (
              <span
                key={`${nd.tableName}-${ind}`}
                className={`rounded-full px-2 py-1 text-xs font-medium text-black mr-2 mb-2 ${renderTextColor(
                  ind
                )}`}
              >
                {nd.tableName}
              </span>
            ))}
          </dd>
        </dl>
      </div>
    </li>
  );
};

export default NetworkBlock;
