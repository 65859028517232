import { FC } from "react";

type LogoLinkProps = {
  name: string;
  logo: string;
  link: string;
};

const LogoLink: FC<LogoLinkProps> = ({ name, logo, link }) => {
  return (
    <div className="flex flex-col items-center">
      <div className="w-16 h-16 flex items-center justify-center">
        <img src={logo} alt={name} className="w-full h-full object-contain" />
      </div>
      <a
        href={link}
        target="_blank"
        rel="noopener noreferrer"
        className="hover:underline"
      >
        {name}
      </a>
    </div>
  );
};

export default LogoLink;
